import React from 'react';
import { graphql, Link } from 'gatsby';

import MainLayout from 'src/layout/MainLayout';
import { SectionHead, Grid } from 'src/components';
import { CardWithBackground } from 'src/components/Cards';

import content from 'src/content/aktuelles/ratgeber';
import subpageNavigations from 'src/data/subpage-navigations';

const RatgeberPage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline,
      position: '75'
    }}
    subpageNavItems={subpageNavigations.aktuelles}
    activeNavItem={2}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.sectionHeadline} centered />
      <Grid columns={2}>
        {content.cards.map((card, index) => (
          <CardWithBackground
            key={index}
            image={data[`cardBackground${index + 1}`]}
            background={card.background}
            headline={card.headline}
          >
            <p>{card.paragraph}</p>
            <Link to={card.link.href} className="arrow-link arrow-gray-blue text-gray-blue">
              {card.link.text}
            </Link>
          </CardWithBackground>
        ))}
      </Grid>
    </section>
  </MainLayout>
);

export default RatgeberPage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-on-couch-laughing-wide.jpg" }
    ) {
      ...image
    }
    cardBackground1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-with-iphone-laughing-small.jpg" }
    ) {
      ...image
    }
    cardBackground2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-with-glases-looking-to-top-small.jpg" }
    ) {
      ...image
    }
    cardBackground3: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-looking-to-top-small.jpg" }
    ) {
      ...image
    }
  }
`;
