const content = {
  title: 'Ratgeber',
  headvisualHeadline: 'Ratgeber',
  sectionHeadline: 'Gut informiert - mit dem Ratgeber der NBB',
  cards: [
    {
      background: 'light-blue',
      headline: 'Arbeitgeberfinanzierte Altersvorsorge',
      paragraph:
        'Gut für Sie – Gut für Ihre Mitarbeiter: Die arbeitgeberfinanzierte Altersvorsorge',
      link: {
        href: '/aktuelles/ratgeber/arbeitgeberfinanzierte-altersvorsorge',
        text: 'Mehr lesen'
      }
    },
    {
      background: 'light-blue',
      headline: 'Betriebliche Altersvorsorge',
      paragraph: 'Betriebliche Altersvorsorge für Geschäftsführer.',
      link: {
        href: '/aktuelles/ratgeber/betriebliche-altersvorsorge',
        text: 'Mehr lesen'
      }
    },
    {
      background: 'light-blue',
      headline: 'Berufsunfähigkeit',
      paragraph: 'Berufsunfähigkeitsschutz clever nutzen.',
      link: {
        href: '/aktuelles/ratgeber/berufsunfaehigkeit',
        text: 'Mehr lesen'
      }
    }
  ]
};

export default content;
